<template>
  <div class="photo__car">
    <img
      v-if="imageArray.length > 1"
      @click="previousSlide(slide)"
      style="margin-right: 10px"
      src="../../assets/images/modal-order/left-arrow-circle.svg"
      alt="Налево"
    />
    <div class="photo__car--item">
      <div v-for="(image, i) in imageArray" :key="i">
        <img
          v-if="slide === i + 1"
          @click="openLightboxOnSlide(i + 1)"
          :src="image"
          :alt="`Фото ${i + 1}`"
        />
      </div>
    </div>
    <div v-if="loadPhoto" class="preloader__car">
      <app-loader></app-loader>
    </div>
    <img
      v-if="imageArray.length > 1"
      @click="nextSlide(slide)"
      style="margin-left: 10px"
      src="../../assets/images/modal-order/right-arrow-circle.svg"
      alt="Направо"
    />
    <FsLightbox
      type="image"
      :slide="slide"
      :toggler="toggler"
      :sources="bigPhoto"
    />
  </div>
</template>

<script>
// LightBox
import FsLightbox from "fslightbox-vue";
// State
import { mapState } from "vuex";
// Preloader
import AppLoader from "@/components/ui/app-loader";

export default {
  name: "PhotoSlider",
  components: { FsLightbox, AppLoader },
  props: {
    imageArray: Array,
    orderId: Number
  },
  data() {
    return {
      toggler: false,
      // slide: 2,
      slide: 1,
      loadPhoto: false,
      bigPhoto: []
    };
  },
  computed: {
    ...mapState({ token: (state) => state.token })
  },
  methods: {
    async loadCarPhoto() {
      this.loadPhoto = true;
      await this.axios
        .get(`/api/client/order/car/photo/list/get/${this.orderId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.loadPhoto = false;
          this.bigPhoto = response.data.response[0];
        })
        .catch((e) => {
          this.loadPhoto = false;
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.getTarifCells();
              });
            } else {
              console.error("Ошибка в получении loadCarPhoto" + e.message);
            }
          }
        });
    },
    openLightboxOnSlide(number) {
      this.loadCarPhoto().then(() => {
        this.slide = number;
        this.toggler = !this.toggler;
      });
    },

    previousSlide(slide) {
      if (slide === 1) {
        this.slide = this.imageArray.length;
      } else {
        this.slide = slide - 1;
      }
    },

    nextSlide(slide) {
      if (slide === this.imageArray.length) {
        this.slide = 1;
      } else {
        this.slide = slide + 1;
      }
    }
  }
};
</script>

<style lang="scss">
.photo__car {
  position: relative;
  @include flex;
  cursor: pointer;

  &--item {
    @include flex;
    border-radius: 8px;
    height: 80px;
    overflow: hidden;
    width: 136px;

    img {
      width: 100%;
    }
  }

  .preloader__car {
    position: absolute;
    left: 32%;
    top: 0;
  }
}
</style>
