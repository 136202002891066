export default {
  methods: {
    // Красивая дата
    getTrueDataToString(data) {
      if (!data) {
        return "";
      } else {
        return new Date(data).toLocaleDateString();
      }
    },
    // Красивое время
    getTrueTimeToString(data) {
      if (!data) {
        return "";
      } else {
        return new Date(data).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit"
        });
      }
    }
  }
};
