<template>
  <div class="main__table" ref="element">
    <div
      v-if="loadDoc"
      style="
        z-index: 1000;
        height: 100vh;
        position: fixed;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
      "
    >
      <app-loader style="margin: 30% 43%"></app-loader>
    </div>
    <div
      v-if="leftViewTab"
      class="main__table--arrblock block__left"
      :style="`height: ${height}px`"
      @mouseover="leftViewTabHandler"
      @mouseleave="clearIntervalHandler"
    >
      <div class="arrblock">
        <img
          class="rotate"
          src="../../assets/images/arr-btx-right.svg"
          alt="Влево"
        />
      </div>
    </div>
    <div
      v-if="rightViewTab"
      class="main__table--arrblock block__right"
      :style="`height: ${height}px`"
      @mouseover="rightViewTabHandler"
      @mouseleave="clearIntervalHandler"
    >
      <div class="arrblock">
        <img src="../../assets/images/arr-btx-right.svg" alt="Вправо" />
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th style="width: 135px">Статус заказа</th>
          <th style="width: 169px">Дата подачи</th>
          <th style="width: 80px">Тоннаж</th>
          <th style="width: 110px">Вид ТС</th>
          <th style="width: 69px">Номер</th>
          <th v-if="userData.useRequestNumber" style="width: 69px">
            Номер заявки
          </th>
          <th style="width: 466px; padding-left: 15px; text-align: left">
            Маршрут
          </th>
          <th style="width: 319px; padding-left: 15px; text-align: left">
            Исполнитель
          </th>
          <th style="width: 72px">GPS</th>
          <th style="width: 95px">Документы</th>
          <th style="width: 111px">Сумма</th>
          <th style="padding-left: 15px; text-align: left">Оплата</th>
        </tr>
      </thead>
      <tbody v-if="orders.length > 0">
        <tr
          v-for="(order, idx) in orders"
          :key="order.id"
          :class="[
            idx % 2 ? '' : ' striple',
            updateOrder === order.orderId ? `active` : ``
          ]"
          class="pointer"
          @click="openModalOrder(order)"
        >
          <td style="width: 135px">
            <section style="position: relative; width: 135px">
              <div
                class="order__status"
                :class="order.orderStatusData.color"
                style="justify-content: center !important; padding: 0"
              >
                {{ order.orderStatusData.name }}
              </div>
              <section
                class="tooltip__status"
                style="
                  height: 32px;
                  position: absolute;
                  right: 0;
                  top: -7px;
                  width: 135px;
                "
                :tooltip="order.orderStatusData.title"
                :flow="
                  orders.length > 10 && orders.length - 10 < idx
                    ? `up-right`
                    : `down-right`
                "
              ></section>
            </section>
          </td>
          <td style="width: 169px">
            {{ order.dateToView }} - {{ order.timeToView }}
          </td>
          <td style="width: 80px">
            {{ order.orderType === 1 ? order.tonn : "Грузчик" }}
          </td>
          <td style="width: 110px">
            {{
              order.orderType === 1 ? order.boxTypeName : order.orderTypeCargo
            }}
          </td>
          <td style="width: 69px">
            {{ order.orderNumber }}
          </td>
          <td v-if="userData.useRequestNumber" style="width: 69px">
            {{ order.requestNumber }}
          </td>
          <td style="width: 466px">
            <div
              v-if="order.lastPointRoute"
              class="orders__route"
              style="position: relative"
            >
              <div style="width: 415px; padding-left: 0" class="main__nowrap">
                <img src="../../assets/images/pin.svg" alt="pin" />
                {{ order.firstPointRoute }}
                <img
                  v-if="order.lastPointRoute"
                  style="margin: 0 15px"
                  src="../../assets/images/arrow-right.svg"
                  alt="pin"
                />
                <img
                  v-if="order.lastPointRoute"
                  src="../../assets/images/pin.svg"
                  alt="pin"
                />
                {{ order.lastPointRoute }}
              </div>
              <div
                class="tooltip__address"
                style="
                  height: 32px;
                  position: absolute;
                  right: 0;
                  top: -8px;
                  width: 33px;
                "
                :tooltip="order.routeTitle"
                :flow="
                  orders.length > 10 && orders.length - 10 < idx
                    ? `up-left`
                    : `down-left`
                "
              >
                <img
                  class="more__info"
                  src="../../assets/images/more_info.svg"
                  alt="info"
                />
              </div>
            </div>
          </td>
          <td style="width: 319px">
            <div style="position: relative">
              <div
                class="main__nowrap"
                style="width: 260px; padding: 0 10px 0 0"
              >
                {{ order.executor ? order.executor : "" }}
              </div>
              <Lottie
                v-if="!order.copy && order.orderStatus !== 5"
                :options="defaultOptions"
                :width="40"
                :height="40"
                style="
                  margin-left: 15px;
                  position: absolute;
                  top: -12px;
                  right: 5px;
                  z-index: 10;
                "
              />
              <div
                class="tooltip__user"
                tooltip="Копировать паспортные данные исполнителя"
                style="position: absolute; right: 0; top: -2px"
                :flow="
                  orders.length > 10 && orders.length - 10 < idx ? `up` : `down`
                "
              >
                <svg
                  v-if="
                    order.copy && order.executor !== `Идет подбор исполнителя`
                  "
                  @click.stop="copyDriverData(order.orderId, order.copy)"
                  class="hover__stroke"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.33325 4.81071V3.27071C4.33334 3.02208 4.43217 2.78367 4.60801 2.60789C4.78384 2.43212 5.02229 2.33337 5.27092 2.33337H13.3956C13.6443 2.33337 13.8828 2.43216 14.0586 2.60801C14.2345 2.78386 14.3333 3.02236 14.3333 3.27104V11.396C14.3332 11.6447 14.2343 11.8831 14.0585 12.0589C13.8827 12.2346 13.6442 12.3334 13.3956 12.3334H11.8389"
                    stroke="#58595B"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.7292 5H2.6035C2.35493 5.00018 2.11661 5.09904 1.9409 5.27487C1.7652 5.4507 1.6665 5.6891 1.6665 5.93767V14.0623C1.6665 14.311 1.76529 14.5495 1.94114 14.7254C2.11699 14.9012 2.35549 15 2.60417 15H10.7292C10.9778 14.9999 11.2162 14.9011 11.392 14.7252C11.5678 14.5494 11.6665 14.311 11.6665 14.0623V5.938C11.6665 5.81484 11.6423 5.69287 11.5952 5.57907C11.5481 5.46527 11.4791 5.36186 11.392 5.27475C11.3049 5.18765 11.2015 5.11855 11.0877 5.07141C10.974 5.02426 10.852 5 10.7288 5H10.7292Z"
                    stroke="#58595B"
                    stroke-width="1.33333"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div style="position: relative">
                <div v-if="copydata.includes(order.orderId)" class="copydata">
                  Скопировано в буфер
                </div>
              </div>
            </div>
          </td>
          <td style="width: 72px">
            <section
              v-if="
                (order.orderStatus === 2 ||
                  order.orderStatus === 3 ||
                  order.orderStatus === 11) &&
                ((order.driverDevice &&
                  (userData.id === 366
                    ? true
                    : order.orderRouteDriverAppCount > 0)) ||
                  order.gps)
              "
              @click.stop="gpsLinkDataClick(order)"
              class="tooltip__docs"
              tooltip="Посмотреть GPS"
              :flow="
                orders.length > 10 && orders.length - 10 < idx ? `up` : `down`
              "
              style="position: relative"
            >
              <svg
                class="hover__fill"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                  fill="#58595B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 3C12.5523 3 13 3.44772 13 4V5.07089C16.0657 5.5094 18.4906 7.93431 18.9291 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H18.9291C18.4906 16.0657 16.0657 18.4906 13 18.9291V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V18.9291C7.93431 18.4906 5.5094 16.0657 5.07089 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H5.07089C5.5094 7.93431 7.93431 5.5094 11 5.07089V4C11 3.44772 11.4477 3 12 3ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12Z"
                  fill="#58595B"
                />
              </svg>
            </section>
            <section
              v-else
              tooltip="GPS отсутствует"
              class="tooltip__docs"
              :flow="
                orders.length > 10 && orders.length - 10 < idx ? `up` : `down`
              "
              style="position: relative"
            >
              <img src="../../assets/images/gps-no.svg" alt="Нет" />
            </section>
          </td>
          <td style="width: 95px">
            <section
              v-if="order.isDocClient"
              @click.stop="openInNewTab(order.orderId)"
              class="tooltip__docs"
              tooltip="Посмотреть скан документов по поездке"
              :flow="
                orders.length > 10 && orders.length - 10 < idx ? `up` : `down`
              "
              style="position: relative"
            >
              <svg
                class="hover__fill"
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.95197 5.75481V0H4.47601C3.96725 0 3.47933 0.202103 3.11959 0.561848C2.75984 0.921593 2.55774 1.40951 2.55774 1.91827V15.9856C2.55774 16.4943 2.75984 16.9823 3.11959 17.342C3.47933 17.7017 3.96725 17.9038 4.47601 17.9038H14.7068C15.2155 17.9038 15.7035 17.7017 16.0632 17.342C16.4229 16.9823 16.625 16.4943 16.625 15.9856V7.67308H10.8702C10.3615 7.67308 9.87356 7.47097 9.51382 7.11123C9.15407 6.75148 8.95197 6.26356 8.95197 5.75481Z"
                  fill="#58595B"
                />
                <path
                  d="M10.2307 5.7548V0.319702L16.3052 6.39422H10.8701C10.7006 6.39422 10.5379 6.32685 10.418 6.20694C10.2981 6.08702 10.2307 5.92438 10.2307 5.7548Z"
                  fill="#58595B"
                />
                <path
                  d="M0 3.83659C0 3.49741 0.134735 3.17214 0.374566 2.9323C0.614396 2.69247 0.939675 2.55774 1.27885 2.55774V15.9856C1.27885 16.8336 1.61568 17.6468 2.21526 18.2463C2.81484 18.8459 3.62803 19.1827 4.47596 19.1827H14.0673C14.0673 19.5219 13.9326 19.8472 13.6927 20.087C13.4529 20.3269 13.1276 20.4616 12.7885 20.4616H4.53735C3.33397 20.4616 2.17988 19.9835 1.32896 19.1326C0.47804 18.2817 0 17.1276 0 15.9242V3.83659Z"
                  fill="#58595B"
                />
              </svg>
            </section>
            <section
              v-else-if="!order.isDocClient"
              class="tooltip__docs"
              tooltip="Скан документов отсутствует"
              :flow="
                orders.length > 10 && orders.length - 10 < idx ? `up` : `down`
              "
              style="position: relative"
            >
              <img src="../../assets/images/docs-no.svg" alt="Нет" />
            </section>
          </td>
          <td style="width: 111px; text-align: right; padding-right: 10px">
            {{ order.sumClient | rounding }} &#8381;
          </td>
          <td>
            <div class="payment__copy">
              <div
                :class="order.statusPayment.color"
                style="width: 120px; margin: 0; padding: 0"
              >
                {{ order.statusPayment.name }}
              </div>
              <div
                @click.stop="copyOrder(order)"
                class="tooltip__docs"
                tooltip="Повторить заказ"
                flow="left"
                style="position: relative"
              >
                <svg
                  class="hover__fill"
                  style="margin: 0 10px"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 1.33333V6C16 6.18056 15.934 6.33681 15.8021 6.46875C15.6701 6.60069 15.5139 6.66667 15.3333 6.66667H10.6667C10.375 6.66667 10.1701 6.52778 10.0521 6.25C9.93403 5.97917 9.98264 5.73958 10.1979 5.53125L11.6354 4.09375C10.6076 3.14236 9.39583 2.66667 8 2.66667C7.27778 2.66667 6.58854 2.80729 5.93229 3.08854C5.27604 3.36979 4.70833 3.75 4.22917 4.22917C3.75 4.70833 3.36979 5.27604 3.08854 5.93229C2.80729 6.58854 2.66667 7.27778 2.66667 8C2.66667 8.72222 2.80729 9.41146 3.08854 10.0677C3.36979 10.724 3.75 11.2917 4.22917 11.7708C4.70833 12.25 5.27604 12.6302 5.93229 12.9115C6.58854 13.1927 7.27778 13.3333 8 13.3333C8.82639 13.3333 9.60764 13.1528 10.3438 12.7917C11.0799 12.4306 11.7014 11.9201 12.2083 11.2604C12.2569 11.191 12.3368 11.1493 12.4479 11.1354C12.5521 11.1354 12.6389 11.1667 12.7083 11.2292L14.1354 12.6667C14.1979 12.7222 14.2309 12.7934 14.2344 12.8802C14.2378 12.967 14.2118 13.0451 14.1562 13.1146C13.3993 14.0312 12.4826 14.7413 11.4062 15.2448C10.3299 15.7483 9.19444 16 8 16C6.91667 16 5.88194 15.7882 4.89583 15.3646C3.90972 14.941 3.05903 14.3715 2.34375 13.6562C1.62847 12.941 1.05903 12.0903 0.635417 11.1042C0.211806 10.1181 0 9.08333 0 8C0 6.91667 0.211806 5.88194 0.635417 4.89583C1.05903 3.90972 1.62847 3.05903 2.34375 2.34375C3.05903 1.62847 3.90972 1.05903 4.89583 0.635417C5.88194 0.211806 6.91667 0 8 0C9.02083 0 10.0087 0.192708 10.9635 0.578125C11.9184 0.963542 12.7674 1.50694 13.5104 2.20833L14.8646 0.864583C15.066 0.649306 15.309 0.600694 15.5938 0.71875C15.8646 0.836806 16 1.04167 16 1.33333Z"
                    fill="#58595B"
                  />
                </svg>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="11" class="empty__data">
            <span>Записей, удовлетворяющих условиям поиска, не найдено</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-intersection="addMore" class="observer">
      <div
        v-if="page < totalPage"
        class="button__load"
        @click="loadingMoreOrders"
      >
        Загрузить еще
      </div>
    </div>
  </div>
</template>

<script>
// Animation
import Lottie from "vue-lottie";
import animationData from "../../assets/animations/circle-loader.js";
import { mapState } from "vuex";
import openBase64 from "@/mixins/open-base64.js";
import AppLoader from "@/components/ui/app-loader";

export default {
  name: "OrdersList",
  props: {
    orders: Array,
    updateOrder: Number,
    page: Number,
    totalPage: Number
  },
  mixins: [openBase64],
  components: { Lottie, AppLoader },
  data() {
    return {
      loadDoc: false,
      copydata: [],
      isModalVisible: false,
      height: 0,
      width: 0,
      leftViewTab: false,
      rightViewTab: false,
      pageWidth: 0,
      scrollWidth: 0,
      resultWidth: 0,
      leftScroll: 0,
      rightScroll: 0,
      defaultOptions: { animationData: animationData },
      animationSpeed: 1
    };
  },
  created() {
    window.addEventListener("resize", this.getElement);
  },
  mounted() {
    this.getElement();
    this.$refs.element.addEventListener("scroll", this.getElementScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getElement);
    this.$refs.element.removeEventListener("scroll", this.getElementScroll);
    this.clearIntervalHandler();
  },
  filters: {
    rounding(value, notTail = 0) {
      if (!value || value === "" || value == "0") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else {
            tail = "." + arr[1];
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    }
  },
  computed: {
    ...mapState(["token", "userData"])
  },
  methods: {
    // Добавление и удаление кнопки загрузки записей
    addMore(value) {
      this.$emit("addMoreOrders", value);
    },
    loadingMoreOrders() {
      this.$emit("loadingMoreOrders");
    },
    // Копирование данных водителя в буфер
    copyDriverData(id, copy) {
      this.copydata.push(id);
      const copytext = document.createElement("textarea");
      copytext.value = copy;
      document.body.appendChild(copytext);
      copytext.select();
      document.execCommand("copy");
      setTimeout(() => {
        this.copydata = [];
        copytext.remove();
      }, 2000);
    },
    // Открываем модальное окно
    openModalOrder(order) {
      this.$emit("openModalOrderHandler", true);
      this.$emit("currentOrderHandler", order);
    },
    gpsLinkDataClick(order) {
      // console.log(order);
      if (order.driverDevice) {
        if (order.orderStatus === 2 || order.orderStatus === 11) {
          this.$emit("viewGPSHandler", {
            name: `driver`,
            value: order.driverData.driverId,
            driver: { data: order.driverData, tonn: order.tonn }
          });
        } else if (order.orderStatus === 3) {
          this.$emit("viewGPSHandler", {
            route: order.allPointsRoute,
            name: `order`,
            value: order.orderId
          });
        }
      } else if (order.gps) {
        this.$emit("viewGPSHandler", { name: `gps`, value: order.gps });
      }
    },
    // Получение высоты браузера
    getHeightPage(height = 190) {
      document.querySelector("body").style = "overflow-y: hidden";
      const pageHight = +document.documentElement.clientHeight,
        tableFixedHight = pageHight - height;
      this.$refs.element.style.height = tableFixedHight + "px";
      return tableFixedHight;
    },
    // Открытие документа в новом окне
    openDocument(value) {
      window.open(value);
    },
    async openInNewTab(id) {
      this.loadDoc = true;
      await this.axios
        .get(`/api/client/order/doc/get/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.loadDoc = false;
          this.printPreview(response.data.response);
        })
        .catch((e) => {
          this.loadDoc = false;
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.getTarifCells();
              });
            } else {
              console.error("Ошибка в получении openInNewTab" + e.message);
            }
          }
        });
    },
    // Ширина элемента таблицы
    getWidthElement() {
      this.elemWidth = +this.$refs.element.clientWidth;
      this.allWidth = +this.$refs.element.scrollWidth;
      this.resultWidth = this.allWidth - this.elemWidth;
      if (this.allWidth !== this.elemWidth) {
        this.rightViewTab = true;
        this.leftViewTab = false;
      } else {
        this.leftViewTab = false;
        this.rightViewTab = false;
      }
    },
    // Получение всех размеров элемента
    getElement() {
      this.getHeightPage();
      this.getWidthElement();
      this.height = this.getHeightPage();
    },
    // Прокрутка по скролу добавление и удаление кнопок
    getElementScroll() {
      if (
        this.$refs.element.scrollLeft === 0 &&
        this.elemWidth !== this.allWidth
      ) {
        this.clearIntervalHandler();
        this.leftViewTab = false;
        this.rightViewTab = true;
      }
      if (
        this.$refs.element.scrollLeft === this.resultWidth &&
        this.elemWidth !== this.allWidth
      ) {
        this.clearIntervalHandler();
        this.leftViewTab = true;
        this.rightViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок влево
    leftViewTabHandler() {
      this.clearIntervalHandler();
      this.leftScroll = setInterval(() => {
        this.addSlideTable(-20, 200);
      }, 100);
      this.rightViewTab = true;
      if (this.$refs.element.scrollLeft === 0) {
        this.clearIntervalHandler();
        this.leftViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок вправо
    rightViewTabHandler() {
      this.clearIntervalHandler();
      this.rightScroll = setInterval(() => {
        this.addSlideTable(20, 200);
      }, 100);
      this.leftViewTab = true;
      if (this.$refs.element.scrollLeft === this.resultWidth) {
        this.clearIntervalHandler();
        this.rightViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок влево и вправо
    addSlideTable(step, period) {
      const startTime = Date.now();
      const render = () => {
        const dt = Date.now() - startTime;
        if (dt < period) {
          // console.log((step * dt) / period);
          this.$refs.element.scrollLeft =
            this.$refs.element.scrollLeft + (step * dt) / period;
          requestAnimationFrame(render);
        }
      };
      requestAnimationFrame(render);
    },
    // Убираем интервал
    clearIntervalHandler() {
      clearInterval(this.rightScroll);
      clearInterval(this.leftScroll);
    },
    // Копирование заказа
    copyOrder(order) {
      this.$emit("copyOrder", order);
    }
  }
};
</script>

<style lang="scss">
.orders__route {
  @include flex;
  @include flex-content-start;
  position: relative;
}

.copydata {
  @include flex;
  background: $color-grey-light;
  // background: $color-white;
  // border: 1px solid $color-basic;
  border-radius: 8px;
  color: $color-black;
  font-size: 14px;
  padding: 8px 10px;
  position: absolute;
  right: -195px;
  top: -18px;
  z-index: 10;
}

.more__info {
  position: absolute;
  right: -10px;
  z-index: 5;
}

.main__table {
  &--arrblock {
    @include flex;
    bottom: 0;
    cursor: pointer;
    height: 100%;
    position: fixed;
    width: 100px;
    user-select: none;
    z-index: 20;

    img {
      // height: 56px;
      // width: 24px;
      height: 100px;
      width: 100px;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    .arrblock {
      @include flex;
      // background: $color-grey-light;
      border-radius: 50%;
      height: 100px;
      opacity: 0.7;
      width: 100px;

      &:hover {
        opacity: 1;
      }
    }

    &.block__right {
      right: -30px;
    }

    &.block__left {
      left: $main-margin-left - 30px;
    }
  }

  &--row {
    position: relative;
    overflow: hidden;
  }
}

.payment__copy {
  @include flex;
  @include flex-content-between;
}

.hover__fill:hover {
  path {
    fill: $status-color-green;
  }
}

.hover__stroke:hover {
  path {
    stroke: $status-color-green;
  }
}

.observer {
  @include flex;
  height: 44px;
}

.button__load {
  @include flex;
  @include button;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  color: #000000;
  margin-top: 10px;
  width: 300px;
}
</style>
