export default {
  methods: {
    printPreview(data) {
      if (data.includes("application/pdf")) {
        const blobURL = URL.createObjectURL(
          this.pdfBlobConversion(data, "application/pdf")
        );
        const theWindow = window.open(blobURL);
        const theDoc = theWindow.document;
        const theScript = document.createElement("script");

        theScript.innerHTML = "window.onload = ${window.print().toString()};";
        theDoc.body.appendChild(theScript);
      } else {
        this.debugBase64(data);
      }
    },
    //converts base64 to blob type for windows
    pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 512;
      b64Data = b64Data.replace(/^[^,]+,/, "");
      b64Data = b64Data.replace(/\s/g, "");
      var byteCharacters = window.atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset = offset + sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    debugBase64(base64URL) {
      var win = window.open();
      win.document.write(
        '<iframe src="' +
          base64URL +
          '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
      );
    }
  }
};
