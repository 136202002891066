<template>
  <div
    v-if="imageArray.length"
    class="executor__block"
    style="margin: 19px 0 0 47px"
  >
    <div style="margin-right: 13px">
      <img src="@/assets/images/docs-yes.svg" alt="Фото по рейсу" />
    </div>
    <div style="position: relative">
      <h4 style="font-size: 14px; font-weight: 500; margin-bottom: 9px">
        Фото по рейсу
      </h4>
      <span
        @click="openLightbox"
        title="Посмотреть фото к рейсу"
        style="
          color: #2196f3;
          cursor: pointer;
          margin-bottom: 8px;
          text-decoration: underline;
        "
      >
        Посмотреть
      </span>
      <span
        v-if="!download"
        @click="callFileZip"
        style="
          color: #2196f3;
          cursor: pointer;
          margin-left: 15px;
          text-decoration: underline;
        "
      >
        Скачать
      </span>
      <Lottie
        v-if="download"
        :options="defaultOptions"
        :width="50"
        :height="50"
        style="position: absolute; top: 10px; right: -50px; z-index: 10"
      />
      <FsLightbox type="image" :toggler="toggler" :sources="imageArray" />
    </div>
  </div>
</template>

<script>
// State
import { mapState, mapActions } from "vuex";
// Animation
import Lottie from "vue-lottie";
import animationData from "../../assets/animations/circle-loader.js";
// LightBox
import FsLightbox from "fslightbox-vue";

export default {
  name: "PhotoDocs",
  components: { FsLightbox, Lottie },
  props: { orderId: Number, imageArray: Array },
  data() {
    return {
      toggler: false,
      fileZip: "",
      download: false,
      defaultOptions: { animationData: animationData },
      animationSpeed: 1
    };
  },
  computed: {
    ...mapState({ token: (state) => state.token })
  },
  methods: {
    ...mapActions(["REFRESH_TOKEN_HANDLER"]),
    openLightbox() {
      this.toggler = !this.toggler;
    },
    // Вызов изображения
    async callFileZip() {
      this.download = true;
      await this.axios
        .get(`/api/client/order/doc/mobile/archive/get/${this.orderId}`, {
          responseType: "blob",
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/zip"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `files-${this.orderId}.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
          this.download = false;
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.callFileZip();
            });
          } else {
            console.error("Ошибка в получении callFileZip" + e.message);
          }
        });
    }
  }
};
</script>
