export const ClientOrderTarifArray = [
  {
    value: "stavka",
    cell: [
      {
        id: "processedHours",
        name: "Отработано часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "stavkaHours",
        name: "Минимально часов в ставке",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 67
      },
      {
        id: "feedTC",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "forwarding",
        name: "Экспеди рование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "totalHours",
        name: "Общее количество часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 85
      },
      {
        id: "rateStavka",
        name: "Тарифы по ставке",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 74
      },
      {
        id: "processingHour",
        name: "Перера ботка за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 76
      },
      {
        id: "hydroBoard",
        name: "Гидро борт",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "exitTtk",
        name: "Въезд ТТК, МОЖД",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 82
      },
      {
        id: "extraPoint",
        name: "Доп. точка",
        all: "Доп. затраты",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "extraCost",
        name: "Стоимость",
        all: 1,
        colspan: 2,
        rowspan: "",
        width: 85
      },
      {
        id: "MkadKm",
        name: "КМ",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "MkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 85
      },
      {
        id: "manualUnloading",
        name: "Ручная разгрузка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "peregruz",
        name: "Перегруз",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "platnayaParkovka",
        name: "Платная парковка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "shtraf",
        name: "Штраф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "inkasaciya",
        name: "Инка сация",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "rastentovka",
        name: "Растен товка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "gruzchikChas",
        name: "Водитель-Грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "gruzchikVremya",
        name: "Водитель-Грузчик время",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "summaClient",
        name: "ИТОГО",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      }
    ]
  },
  {
    value: "clock",
    cell: [
      {
        id: "processedHours",
        name: "Отработано часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "stavkaHours",
        name: "Минимальный заказ, часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 67
      },
      {
        id: "feedTC",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "forwarding",
        name: "Экспеди рование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "totalHours",
        name: "Общее количество часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 85
      },
      {
        id: "rateStavka",
        name: "Тарифы за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 74
      },
      {
        id: "hydroBoard",
        name: "Гидро борт",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "exitTtk",
        name: "Въезд ТТК, МОЖД",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 82
      },
      {
        id: "MkadKm",
        name: "КМ",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "MkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 85
      },
      {
        id: "manualUnloading",
        name: "Ручная разгрузка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "peregruz",
        name: "Перегруз",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "platnayaParkovka",
        name: "Платная парковка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "shtraf",
        name: "Штраф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "inkasaciya",
        name: "Инка сация",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "rastentovka",
        name: "Растен товка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "gruzchikChas",
        name: "Водитель-Грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "gruzchikVremya",
        name: "Водитель-Грузчик время",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "summaClient",
        name: "ИТОГО",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      }
    ]
  },
  {
    value: "point",
    cell: [
      {
        id: "processedHours",
        name: "Отработано часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "minOrderPoint",
        name: "Минимальный заказ, точек",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 67
      },
      {
        id: "feedTC",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "forwarding",
        name: "Экспеди рование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "amountPoints",
        name: "Кол-во точек",
        all: "Оплата за точку",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "costPoint",
        name: "Стоимость за точку",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 85
      },
      {
        id: "hydroBoard",
        name: "Гидро борт",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "exitTtk",
        name: "Въезд ТТК, МОЖД",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 82
      },
      {
        id: "MkadKm",
        name: "КМ",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "MkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 85
      },
      {
        id: "manualUnloading",
        name: "Ручная разгрузка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "peregruz",
        name: "Перегруз",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "platnayaParkovka",
        name: "Платная парковка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "shtraf",
        name: "Штраф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "inkasaciya",
        name: "Инка сация",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "rastentovka",
        name: "Растен товка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "gruzchikChas",
        name: "Водитель-Грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "gruzchikVremya",
        name: "Водитель-Грузчик время",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "summaClient",
        name: "ИТОГО",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      }
    ]
  },
  {
    value: "lenght",
    cell: [
      {
        id: "processedHours",
        name: "Отработано часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "minOrderKm",
        name: "Минимальный заказ, км",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 67
      },
      {
        id: "kmByRoute",
        name: "КМ по маршруту",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 85
      },
      {
        id: "averageTravelSpeed",
        name: "Средняя скорость движения, км/час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "timeSpentRoad",
        name: "Время затраченное на дорогу, мин",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 85
      },
      {
        id: "paidWaitingTime",
        name: "Время ожидания оплачиваемое, мин",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 74
      },
      {
        id: "rateKm",
        name: "Тариф за КМ",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 79
      },
      {
        id: "rateMinWait",
        name: "Тариф за МИН ожидания",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 85
      },
      {
        id: "hydroBoard",
        name: "Гидро борт",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "exitTtk",
        name: "Въезд ТТК, МОЖД",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 82
      },
      {
        id: "manualUnloading",
        name: "Ручная разгрузка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "peregruz",
        name: "Перегруз",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "platnayaParkovka",
        name: "Платная парковка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "shtraf",
        name: "Штраф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "inkasaciya",
        name: "Инка сация",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "rastentovka",
        name: "Растен товка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "gruzchikChas",
        name: "Водитель-Грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "gruzchikVremya",
        name: "Водитель-Грузчик время",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "summaClient",
        name: "ИТОГО",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      }
    ]
  },
  {
    value: "weight",
    cell: [
      {
        id: "processedHours",
        name: "Отработано часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "feedTC",
        name: "Подача ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "forwarding",
        name: "Экспеди рование",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "totalHours",
        name: "Общее количество часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "rateStavkaHour",
        name: "Тариф за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 85
      },
      {
        id: "weightTransportedCargo",
        name: "Вес перевозимого груза",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 74
      },
      {
        id: "rateStavkaKg",
        name: "Тариф по ставке за КГ",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 79
      },
      {
        id: "hydroBoard",
        name: "Гидро борт",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "exitTtk",
        name: "Въезд ТТК, МОЖД",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 82
      },
      {
        id: "MkadKm",
        name: "КМ",
        all: "Километраж за МКАД",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "MkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 85
      },
      {
        id: "manualUnloading",
        name: "Ручная разгрузка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "peregruz",
        name: "Перегруз",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "platnayaParkovka",
        name: "Платная парковка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 73
      },
      {
        id: "shtraf",
        name: "Штраф",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "inkasaciya",
        name: "Инка сация",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "rastentovka",
        name: "Растен товка",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "gruzchikChas",
        name: "Водитель-Грузчик за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "gruzchikVremya",
        name: "Водитель-Грузчик время",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "summaClient",
        name: "ИТОГО",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      }
    ]
  },
  {
    value: "sber",
    cell: [
      {
        id: "addressFeed",
        name: "Адрес подачи",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 130
      },
      {
        id: "startTimeWorkAuto",
        name: "Время начала работы ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "endTimeWorkAuto",
        name: "Время окончания работы ТС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "timeTripAddressToAddress",
        name: "Время поездки с адреса на адрес",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "timeWaitMin",
        name: "Ожидание, мин",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "minInAddressNoFirstMin",
        name: "Кол-во мин на адресе без учета первых мин",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "distanceRoute",
        name: "Протяженность маршрута, КМ",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "startTimeWorkLoader",
        name: "Время начала работы грузчиков",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "endTimeWorkLoader",
        name: "Время окончания работы грузчиков",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "timeWorkLoader",
        name: "Время работы грузчика",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "paymentPRR",
        name: "Оплачиваемые ПРР, мин",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 65
      },
      {
        id: "paymentTimeWorkAutoMinusTimeWorkLoader",
        name: "Оплачиваемое время работы ТС за минусом времени работы грузчика",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "normaPRR",
        name: "Норматив на ПРР (не более мин)",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 70
      },
      {
        id: "weightKG",
        name: "Вес (кг)",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "loadCapacity",
        name: "Грузоподъемность ТС, тонн",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "countLoader",
        name: "Кол-во грузчиков",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 60
      },
      {
        id: "rateKmAuto",
        name: "Тариф руб/КМ ТС без НДС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "rateTimeWait",
        name: "Тариф за время ожидания руб/МИН ТС без НДС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 80
      },
      {
        id: "ratePRR",
        name: "Тариф ПРР руб/МИН без НДС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "sumPRR",
        name: "Сумма ПРР без НДС, руб",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "sumWorkAuto",
        name: "Сумма работ ТС без НДС, руб",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "transportExpenses",
        name: "Транспортные расходы руб без НДС",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      },
      {
        id: "itogo",
        name: "Итого без НДС, руб",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 75
      }
    ]
  }
];
