export const OrderLoaderTarifArray = [
  {
    cell: [
      {
        id: "loaderType",
        name: "Вид груза",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 112
      },
      {
        id: "processingHour",
        name: "Отработано часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 95
      },
      {
        id: "minOrderHour",
        name: "Минимально заказ часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 112
      },
      {
        id: "feedLoaderDestination",
        name: "Подача грузчика в место назначения",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 122
      },
      {
        id: "totalHours",
        name: "Общее количество часов",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 78
      },
      {
        id: "rateHour",
        name: "Тариф за час",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 82
      },
      {
        id: "mkadKm",
        name: "КМ",
        all: "Отдаленность от МКАД",
        colspan: 2,
        rowspan: "",
        width: 79
      },
      {
        id: "mkadCost",
        name: "Стоимость",
        all: 1,
        colspan: "",
        rowspan: "",
        width: 85
      },
      {
        id: "plusAddAmount",
        name: "Плюс доп. сумма",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      },
      {
        id: "sumClient",
        name: "ИТОГО",
        all: "",
        colspan: "",
        rowspan: 2,
        width: 71
      }
    ]
  }
];
