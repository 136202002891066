export const orderStatus = [
  {
    color: "orange",
    name: "Новый",
    title: "Заказ принят, подбираем исполнителя"
  },
  {
    color: "blue",
    name: "В работе",
    title: "Исполнитель назначен, контролируем выполнение"
  },
  { color: "green", name: "Доставлен", title: "Заказ доставлен" },
  { color: "green", name: "Выполнен" },
  { color: "red", name: "Отменен", title: "Вы отменили заказ" },
  { color: "red", name: "Отказ", title: "Мы отказались от заказа" },
  { color: "red", name: "Пустой" },
  { color: "grey", name: "Архив" },
  { color: "red", name: "Удалён" },
  { color: "red", name: "Заявка" },
  { color: "blue", name: "Выполняется" },
  {
    color: "orange",
    name: "Новый",
    title: "Заказ принят, подбираем исполнителя"
  }
];
