<template>
  <div style="display: none">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
import L from "leaflet";
import { IRouter, IGeocoder, LineOptions } from "leaflet-routing-machine";
import { findRealParent } from "vue2-leaflet";
const props = {
  visible: {
    type: Boolean,
    default: true
  },
  waypoints: {
    type: Array,
    required: true
  },
  router: {
    type: IRouter
  },
  plan: {
    type: L.Routing.Plan
  },
  geocoder: {
    type: IGeocoder
  },
  fitSelectedRoutes: {
    type: [String, Boolean],
    default: "smart"
  },
  lineOptions: {
    type: LineOptions
  },
  routeLine: {
    type: Function
  },
  autoRoute: {
    type: Boolean,
    default: true
  },
  routeWhileDragging: {
    type: Boolean,
    default: false
  },
  routeDragInterval: {
    type: Number,
    default: 500
  },
  waypointMode: {
    type: String,
    default: "connect"
  },
  useZoomParameter: {
    type: Boolean,
    default: false
  },
  showAlternatives: {
    type: Boolean,
    default: false
  },
  altLineOptions: {
    type: LineOptions
  }
};
// const optionTestNames = [
//   'router',
//   'plan',
//   'geocoder',
//   'lineOptions',
//   'routeLine',
//   'altLineOptions'
// ]
export default {
  props,
  name: "LeafletRouting",
  data() {
    return {
      parentContainer: null,
      ready: false,
      layer: null
    };
  },
  mounted() {
    this.parentContainer = findRealParent(this.$parent);
    this.add();
    this.ready = true;
  },
  beforeDestroy() {
    return this.layer ? this.layer.remove() : null;
  },
  methods: {
    add() {
      if (this.parentContainer._isMounted) {
        const { waypoints } = this;
        const options = {
          // waypoints,
          lineOptions: {
            // Опции линии
            styles: [
              {
                color: "green",
                opacity: "0.7",
                weight: 4
              }
            ]
          },
          plan: L.Routing.plan(waypoints, {
            createMarker: function (i, wp) {
              const LeafIcon = L.Icon.extend({
                options: {
                  iconSize: [50, 50],
                  iconAnchor: [45, 25],
                  shadowSize: [40, 29],
                  shadowAnchor: [24, 3]
                }
              });
              const icon = new LeafIcon({
                iconUrl: require(`@/assets/images/leaflet/${i + 1}.png`),
                shadowUrl: require(`@/assets/images/leaflet/shadow.png`)
              });
              return wp.name
                ? L.marker(wp.latLng, {
                    icon,
                    draggable: false
                  }).bindTooltip(wp.name)
                : L.marker(wp.latLng, {
                    icon,
                    draggable: false
                  });
            },
            routeWhileDragging: false
          }),
          addWaypoints: false, // Добавление точки на маршруте
          draggableWaypoints: false, // Перетаскивание точек
          show: false // Блок сверху с улицами
        };
        const { mapObject } = this.parentContainer;
        const routingLayer = L.Routing.control(options);
        routingLayer.addTo(mapObject);
        this.layer = routingLayer;
      }
    }
  }
};
</script>
