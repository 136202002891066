<template>
  <l-map
    v-if="mounted"
    style="height: 100%; width: 100%"
    :zoom="zoom"
    :center="center"
    :options="{ attributionControl: false }"
  >
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker v-if="typeDriver" :latLng="marker">
      <l-tooltip :content="driverTooltip" :options="markerToltip"></l-tooltip>
    </l-marker>
    <leaflet-routing v-if="typeOrder" :waypoints="waypoints"></leaflet-routing>
    <l-control-attribution
      position="topright"
      prefix=""
    ></l-control-attribution>
    <!-- <div class="route__points">
      <div class="route" v-for="point in getRoute" :key="point.id">
       <span>С точки {{ point.pre }}</span><span>до точки {{ point.next }}</span><span>дистанция {{ point.length }}км.</span>
      </div>
    </div> -->
    <div v-if="userData.id === 366 && getRoute && typeOrder" class="distance">{{getRoute}}км.</div>
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LControlAttribution
} from "vue2-leaflet";
import { Icon } from "leaflet";
// Routing Leaflet
import LeafletRouting from "./leaflet-routing.vue";

// Store
import { mapState } from "vuex";
// Mixins
import dateTimeFunctions from "@/mixins/date-time-functions";

export default {
  name: "TheLeaflet",
  props: {
    viewRoute: Array,
    driverId: Number,
    driverData: Object,
    orderId: Number,
    typeOrder: { type: Boolean, default: false },
    typeDriver: { type: Boolean, default: false }
  },
  mixins: [dateTimeFunctions],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LeafletRouting,
    LControlAttribution
  },
  data() {
    return {
      mounted: false,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
      attribution: "",
      zoom: 11,
      center: [55.753544, 37.621211],
      markerToltip: {},
      driverTooltip: "",
      waypoints: [],
      marker: [],
      iconBlue: require(`@/assets/images/leaflet/blue.png`),
      iconGrey: require(`@/assets/images/leaflet/grey.png`),
      shadowUrl: require(`@/assets/images/leaflet/shadow.png`),
      working: true,
      checkDriverPoint: 0
    };
  },
  computed: {
    ...mapState(["token", "userData"]),
    // getRoute() {
    //   const routes = [];
    //   for(let i = 0; i<this.viewRoute.length - 1; i++) {
    //     const obj = {}
    //     obj.next = (i < this.viewRoute.length) ? i+2 : null
    //     obj.length = (i < this.viewRoute.length) ? this.viewRoute[i+1].distance : null
    //     obj.id = i
    //     obj.pre = i+1
    //     routes.push(obj)
       
    //   }
    //   return routes
    // }
    getRoute() {
      const initialValue = 0
      return this.viewRoute.reduce(
  (accumulator, currentValue) => accumulator + +currentValue.distance,
  initialValue,
);
    }
  },
  methods: {
    // Получение данных по текущему положению
    async driverLastCoordinates() {
      await this.axios
        .get(`/api/driver/last-coordinates/get/${this.driverId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          const latitude = +response.data.response.lat,
            longitude = +response.data.response.lng,
            timeLast = +response.data.response.timestamp;
          this.marker = [latitude, longitude];
          this.center = [latitude, longitude];
          this.zoom = 16;
          this.driverTooltip = `
          <ul style="list-style: none">
            <li>${this.driverData.data.driverFullName}</li>
            <li>${this.driverData.data.driverCarModel}</li>
            <li>${this.driverData.data.driverCarNumber}</li>
            <li>${this.driverData.tonn}</li>
            <li>${this.driverData.data.driverPhone}</li>
          </ul>`;
          const timeNow = Date.now(),
            hour = 3600000;
          console.log(timeNow, timeLast, hour);
          timeNow - timeLast < hour
            ? (this.working = true)
            : (this.working = false);
          // console.log(response.data.response);
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.driverLastCoordinates();
            });
          } else {
            console.error(
              "Ошибка в получении driverLastCoordinates" + e.message
            );
          }
        });
    },
    async orderRoutesCoordinates() {
      await this.axios
        .get(`/api/order/routes/coordinates/get/${this.orderId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          // console.log(response.data.response);
          const routes = response.data.response;
          if (routes.length) {
            routes.map((item) => {
              const temp = item.coordinates.split(","),
                temObj = {};
              temObj.lat = +temp[0];
              temObj.lng = +temp[1];
              const name =
                this.userData.clientOptions.hideRoutesTime !== 1
                  ? `<div>${item.address ? item.address : ""}</div>
                  ${
                    item.dateTimeStart
                      ? `<div>Прибыл:</div>
                  <div>${this.getTrueDataToString(
                    item.dateTimeStart
                  )} ${this.getTrueTimeToString(item.dateTimeStart)}</div>`
                      : ``
                  }
                  ${
                    item.dateTimeEnd
                      ? `<div>Убыл:</div>
                  <div>${this.getTrueDataToString(
                    item.dateTimeEnd
                  )} ${this.getTrueTimeToString(item.dateTimeEnd)}</div>`
                      : ``
                  }`
                  : null;
              this.waypoints.push({
                latLng: { lat: +temp[0], lng: +temp[1] },
                name
              });
            });
            // console.log(this.waypoints);
          }
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.orderRoutesCoordinates();
            });
          } else {
            console.error(
              "Ошибка в получении orderRoutesCoordinates" + e.message
            );
          }
        });
    },
    // Получение данных по маршруту у доставленного заказа
    orderRoutesCoordinatesSber() {
      const routes = this.viewRoute;
      if (routes.length) {
        routes.map((item) => {
          const temp = item.coordinates.split(","),
            temObj = {};
          temObj.lat = +temp[0];
          temObj.lng = +temp[1];
          const name =
            this.userData.clientOptions.hideRoutesTime !== 1
              ? `<div>${item.address ? item.address : ""}</div>
                  ${
                    item.arrival
                      ? `<div>Прибыл:</div>
                  <div>${this.getTrueDataToString(
                    item.arrival
                  )} ${this.getTrueTimeToString(item.arrivalTime)}</div>`
                      : ``
                  }
                  ${
                    item.departed
                      ? `<div>Убыл:</div>
                  <div>${this.getTrueDataToString(
                    item.departed
                  )} ${this.getTrueTimeToString(item.departedTime)}</div>`
                      : ``
                  }`
              : null;
          this.waypoints.push({
            latLng: { lat: +temp[0], lng: +temp[1] },
            name
          });
        });
        // console.log(this.waypoints);
      }
    },
    addMarkerIcon() {
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconUrl: !this.working ? this.iconGrey : this.iconBlue,
        iconRetinaUrl: !this.working ? this.iconGrey : this.iconBlue,
        shadowUrl: this.shadowUrl,
        iconSize: [50, 50],
        iconAnchor: [18, 40],
        shadowSize: [40, 29],
        shadowAnchor: [0, 16]
      });
    }
  },
  mounted() {
    if (this.typeOrder) {
      if (this.userData.id === 366) {
        this.orderRoutesCoordinatesSber();
        this.mounted = true;
      } else {
        this.orderRoutesCoordinates().then(() => {
          this.mounted = true;
        });
      }
    }
    if (this.typeDriver) {
      this.driverLastCoordinates().then(() => {
        this.mounted = true;
        this.addMarkerIcon();
      });
      this.checkDriverPoint = setInterval(() => {
        this.driverLastCoordinates();
      }, 5000);
    }
  },
  destroyed() {
    clearInterval(this.checkDriverPoint);
  }
};
</script>

<style lang="scss">
.distance {
  margin: 8px;
  position: absolute;
  z-index: 2000;
  right: 0;
  background-color: white;
  width:60px;
  height: 40px;
  border-radius: 16px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.route__points {
  z-index: 2000;
  right: 0;
  background-color: white;
  width: 250px;
  height: 200px;
  position: absolute;
  .route{
    display: flex;
    gap: 5px;
    color: black;
    span {
      padding: 5px;
    }
  }
}
.leaflet-routing-alternatives-container {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  max-height: 320px;
  background-color: white;
}
.leaflet-routing-alt {
  display: block !important;
}
</style>